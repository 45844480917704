<template>
  <section class="news-block">
    <h2>L'actualité des entreprises Françaises</h2>
    <div class="news-grid">
      <NewsColumn :scroll-block="accordsId" :items="accords">
        <template #title>
          <SheetIcon />
          Les derniers accords d’entreprises
        </template>
      </NewsColumn>
      <NewsColumn :scroll-block="collectiveProceduresId" :items="collectives">
        <template #title>
          <ScalesIcon />
          Les derniéres procédures collectives
        </template>
      </NewsColumn>
      <NewsColumn :scroll-block="financiereId" :items="financiere">
        <template #title>
          <ChartIcon />
          Les dernières info des sociétés côtées
        </template>
      </NewsColumn>
    </div>
    <swiper v-if="!isPhone && isMobile" class="swiper" :options="swiperOption">
      <swiper-slide>
        <NewsColumn :scroll-block="accordsId" :items="accords">
          <template #title>
            <SheetIcon />
            Les derniers accords d’entreprises
          </template>
        </NewsColumn>
      </swiper-slide>
      <swiper-slide>
        <NewsColumn :scroll-block="collectiveProceduresId" :items="collectives">
          <template #title>
            <ScalesIcon />
            Les derniéres procédures collectives
          </template>
        </NewsColumn>
      </swiper-slide>
      <swiper-slide>
        <NewsColumn :scroll-block="financiereId" :items="financiere">
          <template #title>
            <ChartIcon />
            Les dernières info des sociétés côtées
          </template>
        </NewsColumn>
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination swiper-pagination-bullets" />
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import NewsColumn from './parts/NewsColumn'
import SheetIcon from '@/assets/svg/icons/sheet.svg'
import ScalesIcon from '@/assets/svg/icons/scales.svg'
import ChartIcon from '@/assets/svg/icons/chart.svg'
import deviceInfo from '@/mixins/deviceInfo'
import companiesIds from '~/mixins/companiesIds'
import 'swiper/css/swiper.css'

export default {
  name: 'NewsBlock',
  components: {
    NewsColumn, SheetIcon, ScalesIcon, ChartIcon, Swiper, SwiperSlide
  },
  mixins: [deviceInfo, companiesIds],
  props: {
    accords: {
      default: () => []
    },
    collectives: {
      default: () => []
    },
    financiere: {
      default: () => []
    }
  },
  data () {
    return {
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet (index, className) {
            return `<span style="width: 12px; height: 12px;" class="${className} swiper-pagination-bullet-custom"/></span>`
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.news-block {
  margin: 100px auto 0;
  max-width: 1170px;

  .news-grid {
    max-width: max-content;
    margin: 30px auto 0;
    @include flex-line-center;
    flex-wrap: wrap;
    gap: 30px;
  }

  .swiper {
    display: none;
  }

  h2 {
    font-size: var(--font-size-normal-l3);
    line-height: var(--line-height-large-s2);
    font-weight: 600;
    font-family: Poppins, sans-serif;
  }
}

@include tablet-landscape {
  .news-block {
    margin-top: 50px;
    max-width: 100%;
    width: 100vw;

    h2 {
      text-align: center;
    }

    .news-grid {
      display: none;
    }

    .swiper {
      display: flex;
      gap: 30px;
      margin-top: 30px;
      padding-bottom: 30px;

      .swiper-slide {
        display: flex;
        justify-content: center;
      }

      .swiper-pagination {
        bottom: 0;
      }
    }
  }
}

@include phone {
  .news-block {
    .news-grid {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .swiper {
      display: none;
    }
  }
}
</style>
