<template>
  <div
    class="top-background"
    :class="{ 'top-background_clip': clip }"
    :style="variables"
  >
    <TopBackgroundTexture class="texture" :style="params" />
  </div>
</template>
<script>
import deviceInfo from '@/mixins/deviceInfo'

const DEFAULT_PARAMS = {
  '--start-background-image-position-x': '-772px',
  '--start-background-image-position-y': '-580px',
  '--start-background-image-position-x-mobile': '-25%',
  '--start-background-image-position-y-mobile': '-3%',
  '--bg-width': '2820px',
  '--bg-height': '1924px',
  '--bg-width-mobile': '1410px',
  '--bg-height-mobile': '960px'
}

export default {
  name: 'TopBackground',
  components: { TopBackgroundTexture: () => import('@/static/svg/top-background-texture.svg') },
  mixins: [deviceInfo],
  props: {
    params: {
      default: () => DEFAULT_PARAMS
    },
    clip: {
      default: true
    },
    height: {
      default: () => ({
        desktop: {
          full: '507px',
          leftPercent: 100,
          rightPercent: 83
        },
        mobile: {
          full: 'calc(100% - 122px)',
          leftPercent: 100,
          rightPercent: 85.4
        },
        phone: {
          full: 'calc(100% - 60px)',
          leftPercent: 100,
          rightPercent: 85.4
        }
      })
    }
  },
  data () {
    return {
      parallax: true,
      offsetX: 0,
      offsetY: 0
    }
  },
  computed: {
    variables () {
      let item = {
        '--offset-x': `${this.offsetX}px`,
        '--offset-y': `${this.offsetY}px`,
        '--height': this.realFullHeight,
        '--left-percent': this.realLeftPercent,
        '--right-percent': this.realRightPercent
      }

      item = Object.assign(item, DEFAULT_PARAMS)
      item = Object.assign(item, this.params)

      return item
    },
    realFullHeight () {
      let { height } = this
      if (this.isPhone && this.height.phone && this.height.phone.full) height = this.height.phone.full
      else if (this.isMobile && this.height.mobile && this.height.mobile.full) height = this.height.mobile.full
      else if (this.height.desktop && this.height.desktop.full) height = this.height.desktop.full
      if (!isNaN(height)) height += 'px'
      return height
    },
    realLeftPercent () {
      let percent = 100
      if (this.isPhone && this.height.phone && this.height.phone.leftPercent) percent = this.height.phone.leftPercent
      else if (this.isMobile && this.height.mobile && this.height.mobile.leftPercent) percent = this.height.mobile.leftPercent
      else if (this.height.desktop && this.height.desktop.leftPercent) percent = this.height.desktop.leftPercent
      if (!isNaN(percent)) percent += '%'
      return percent
    },
    realRightPercent () {
      let percent = 100
      if (this.isPhone && this.height.phone && this.height.phone.rightPercent) percent = this.height.phone.rightPercent
      else if (this.isMobile && this.height.mobile && this.height.mobile.rightPercent) percent = this.height.mobile.rightPercent
      else if (this.height.desktop && this.height.desktop.rightPercent) percent = this.height.desktop.rightPercent

      if (!isNaN(percent)) percent += '%'
      return percent
    }
  },
  mounted () {
    if (this.parallax && !this.isMobile) {
      document.addEventListener('mousemove', this.mouseMove)
      this.requestedFrame = null
    }
  },
  beforeDestroy () {
    if (this.parallax && !this.isMobile) {
      document.removeEventListener('mousemove', this.mouseMove)
    }
  },
  methods: {
    mouseMove (e) {
      if (this.requestedFrame !== null) {
        return
      }
      this.requestedFrame = requestAnimationFrame(() => {
        const x = this.windowWidth / 2
        this.offsetX = (e.clientX - x) / (this.windowWidth / 20)
        const y = this.windowHeight / 2
        this.offsetY = (e.clientY - y) / (this.windowHeight / 20)
        this.requestedFrame = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-background {
  overflow: hidden;
  background-color: var(--brand);
  height: var(--height);
  position: relative;
  border-radius: inherit;
  .texture {
    border-radius: inherit;
    width: var(--bg-width);
    height: var(--bg-height);
    mix-blend-mode: soft-light;
    position: relative;
    will-change: transform;
    transform: translate(
      calc(var(--start-background-image-position-x) + var(--offset-x)),
      calc(var(--start-background-image-position-y) + var(--offset-y))
    );
    opacity: 0.3;
  }

  &_clip {
    clip-path: polygon(
      100% 0,
      100% var(--right-percent),
      0% var(--left-percent),
      0% 0%
    );
  }

  @include tablet-landscape {
    & {
      .texture {
        width: var(--bg-width-mobile);
        height: var(--bg-height-mobile);
        transform: translate(
            calc(var(--start-background-image-position-x-mobile)),
            calc(var(--start-background-image-position-y-mobile))
        );
      }
    }
  }
}
</style>
