export default {
  data () {
    return {
      title: null,
      meta: [],
      script: []
    }
  },
  head () {
    return {
      title: this.title,
      meta: this.meta,
      script: this.script
    }
  }
}
