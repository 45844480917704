<template>
  <section class="annuaire-n-recherchers">
    <article class="left-side">
      <h2>Annuaire des entreprises par département</h2>
      <hr>
      <WrappedList :items="annuaireList" />
      <NuxtLink to="/annuaire" class="annuaire-link">
        Voir toutes les départements
      </NuxtLink>
    </article>
    <article class="right-side">
      <h2>Les dernières recherches</h2>
      <hr>
      <WrappedList :items="lastRecherchers" />
    </article>
  </section>
</template>

<script>
import WrappedList from './parts/WrappedList.vue'
import deviceInfo from '@/mixins/deviceInfo'

export default {
  name: 'AnnuaireAndLastRecherchers',
  components: { WrappedList },
  mixins: [deviceInfo],
  props: {
    annuaireList: {
      default: () => []
    },
    lastRecherchers: {
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.annuaire-n-recherchers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 30px;
  max-width: 1170px;
  margin: 100px auto 0;

  h2 {
    margin: 0;
    font-weight: 600;
    font-size: var(--font-size-normal-l3);
    line-height: var(--line-height-large-s2);
    color: var(--title);
    font-family: Poppins, sans-serif;
  }

  hr {
    margin: 14px 0 40px;
    height: 2px;
  }

  .annuaire-link {
    display: inline-block;
    margin-top: 40px;
    color: var(--brand);
    font-weight: 700;
    position: relative;
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--hovered-brand);
    }

    &:active {
      color: var(--pressed-brand);
    }

    &:focus {
      outline: none;
    }

    &::after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 4px;
      width: 100%;
      height: 1px;
      background: currentColor;
      content: "";
      transition: all 0.25s ease;
    }

    &:focus-visible {
      &::after {
        bottom: 1px;
        width: calc(100% + 10px);
        height: 2px;
      }

      outline: none;
    }
  }

  .list {
    height: 170px;
  }
  @include tablet-landscape {
    & {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      gap: 50px;
      width: calc(100vw - 40px);
      margin-top: 70px;
      max-width: 570px;

      .annuaire-link {
        margin-top: 30px;
      }

      h2 {
        margin-bottom: 14px;
        font-size: 24px;
        line-height: var(--line-height-normal-l2);
      }
    }
  }

  @include phone {
    & {
      h2 {
        font-size: 20px;
        margin-bottom: 30px;
      }

      hr {
        display: none;
      }
    }
  }
}
</style>
