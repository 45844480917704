<template>
  <div class="top-hero">
    <TopBackground class="background" />
    <div class="foreground">
      <h1 class="splash">
        Kbis, Siren/siret, bilans, statuts...
      </h1>
      <h2 class="header">
        Toute l'info légale et financière en illimité 🚀
      </h2>
      <LazyHydrate :on-interaction="['click', 'touchstart', 'focus']">
        <SearchInput class="search-block" :limit="5" @search="search" />
      </LazyHydrate>
      <LazyHydrate when-idle>
        <Stats class="stats" :items="counters" />
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import Stats from '~/components/main-page/parts/Stats.vue'
import TopBackground from '~/components/common/parts/TopBackground.vue'
import SearchInput from '~/components/forms/SearchInput.vue'
import deviceInfo from '@/mixins/deviceInfo'
import search from '~/mixins/search/search'

export default {
  name: 'TopHero',
  components: {
    TopBackground,
    Stats,
    SearchInput,
    LazyHydrate
  },
  mixins: [deviceInfo, search],
  props: {
    counters: {
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
.top-hero {
  height: 542px;
  max-width: 100vw;
  position: relative;

  .background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }

  .foreground {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    @include flex-line-start;
    flex-direction: column;
  }

  .splash {
    margin-top: 66px;
    padding: 10px 12px;
    background: var(--secondary);
    @include small-font;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 10px;
  }

  .header {
    margin-top: 10px;
    color: var(--white);
    @include biggest-font;
    text-align: center;
    max-width: 580px;
    width: calc(100vw - 40px);
    font-weight: 600;
    font-family: Poppins, sans-serif;
  }

  .stats {
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    left: 50%;
  }

  .search-block {
    margin-top: 30px;
    width: 605px;
    max-width: calc(100vw - 40px);
  }
}

@include tablet-landscape {
  .top-hero {
    height: 554px;

    .stats {
      bottom: 110px;
    }

    .header {
      font-size: var(--font-size-normal-l3);
      line-height: var(--line-height-large-s2);
    }

    .search-block {
      width: 570px;
    }
  }
}

@include phone {
  .top-hero {
    .stats {
      bottom: -60px;
    }

    .header {
      width: calc(100vw - 20px);
    }

    .search-block {
      max-width: calc(100vw - 40px);
    }
  }
}
@media (max-width: 320px) {
  .top-hero {
    .search-block {
      max-width: calc(100vw - 32px);
    }
  }
}
</style>
