<template>
  <div class="wrap">
    <template v-for="(item, i) in items">
      <div :key="i" class="stat">
        <p class="number">
          {{ numberSuffix(item.value, 0, true) }}
        </p>
        <p class="title">
          {{ item.title }}
        </p>
      </div>
      <hr v-if="i!==items.length-1" :key="i+'hr'" class="separator">
    </template>
  </div>
</template>

<script>
import numbers from '@/mixins/numbers'

export default {
  name: 'Stats',
  mixins: [numbers],
  props: {
    items: {
      default: () => [
        { title: 'Documents', value: 25426225 }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: fit-content;
  display: flex;
  gap: 50px;
  background: var(--white);
  min-width: 280px;

  border-radius: 40px;
  padding: 35px 60px 43px 60px;
  box-shadow: 0px 100px 80px rgba(115, 132, 156, 0.07),
    0px 64.8148px 46.8519px rgba(115, 132, 156, 0.0531481),
    0px 38.5185px 25.4815px rgba(115, 132, 156, 0.0425185),
    0px 20px 13px rgba(115, 132, 156, 0.035),
    0px 8.14815px 6.51852px rgba(115, 132, 156, 0.0274815),
    0px 1.85185px 3.14815px rgba(115, 132, 156, 0.0168519);
}

.separator {
  border: none;
  outline: none;
  background: var(--separator);
  height: 50px;
  border: 1px solid var(--separator);
  margin: 0;

  margin-top: 15px;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;

  .number {
    @include biggest-font;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: var(--title);
  }

  .title {
    @include small-font;
    font-weight: 600;
    color: var(--light-gray);
  }
}

@include tablet-landscape {
  .wrap {
    max-width: 627px;
    gap: 40px;
    padding: 28px 48px;

    .stat {
      .number {
        font-size: 28px;
      }
    }
  }
}

@include phone {
  .wrap {
    display: flex;
    gap: 0px;
    padding: 50px 40px;
    flex-wrap: wrap;
    justify-content: center;

    .stat:first-child {
      margin-bottom: 30px;
    }

    .stat:nth-child(2) {
      margin-bottom: 30px;
    }
  }

  .separator {
    display: none;
  }

  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    .number {
      @include big-font;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      color: var(--title);
    }

    .title {
      @include small-font;
      font-weight: 600;
      color: var(--light-gray);
    }
  }
}
</style>
