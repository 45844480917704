<template>
  <div class="main-page">
    <TopHero :counters="counters" />
    <div class="main-content">
      <LazyHydrate never>
        <SourcesAndPartners v-if="!isPhone" />
      </LazyHydrate>
      <OurFeatures :companies="mostPopularCompanies" />
      <LazyHydrate when-visible>
        <NewsBlock :accords="accords" :collectives="collectives" :financiere="financiere" />
      </LazyHydrate>
      <LazyHydrate when-visible>
        <AnnuaireAndLastRecherchers :annuaire-list="annuaire" :last-recherchers="recherchers" />
      </LazyHydrate>
      <LazyHydrate v-if="!hasSubscription" when-visible>
        <PremiumInvitation />
      </LazyHydrate>
      <LazyHydrate v-if="!isPhone && !$store.getters['scripts/getMerchantName']" when-visible>
        <PressInfo :is-mobile="isMobile" />
      </LazyHydrate>
      <LazyHydrate never>
        <TextContent />
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import deviceInfo from '@/mixins/deviceInfo'
import TopHero from '@/components/main-page/TopHero.vue'
import OurFeatures from '@/components/main-page/OurFeatures.vue'
import NewsBlock from '@/components/main-page/NewsBlock.vue'
import AnnuaireAndLastRecherchers from '@/components/main-page/AnnuaireAndLastRecherchers.vue'
import TextContent from '~/components/layout/parts/TextContent.vue'
import loadHead from '~/mixins/loadHead'
import { asyncDataLoadHead } from '~/services/head'
import { MAIN_ALIAS } from '~/services/consts'
import userInfo from '~/mixins/userInfo'

export default {
  name: 'IndexPage',
  components: {
    LazyHydrate,
    SourcesAndPartners: () => import('@/components/main-page/parts/SourcesAndPartners.vue'),
    TopHero,
    OurFeatures,
    NewsBlock,
    AnnuaireAndLastRecherchers,
    PremiumInvitation: () => import('~/components/common/PremiumInvitation.vue'),
    PressInfo: () => import('~/components/main-page/PressInfo.vue'),
    TextContent
  },
  mixins: [deviceInfo, loadHead, userInfo],
  async asyncData ({
    $api, $config, store
  }) {
    const data = { annuaire: [] }
    const requests = []
    requests.push($api.services.counters().then(x => {
      data.info = x
      data.counters = [
        { title: 'Documents', value: x.files },
        { title: 'Enterprises', value: x.companies },
        { title: 'Bilans', value: x.bilans },
        { title: 'Contacts', value: x.contacts }
      ]
      data.accords = []
      data.collectives = []
      data.financiere = []
      for (const o of x.last_acco) {
        data.accords.push({
          title: o.title,
          date: o.date,
          text: o.company_name ? `${o.company_name} - ${o.siret}` : o.siret,
          alias: o.link_to_company
        })
      }
      for (const o of x.last_collective) {
        data.collectives.push({
          title: o.company_name,
          date: o.date,
          text: `${o.siren} - ${o.title}`,
          alias: o.link_to_company
        })
      }
      for (const o of x.last_financiere) {
        data.financiere.push({
          title: o.company_name,
          date: o.date,
          text: `${o.siren} - ${o.title}`,
          alias: o.link_to_company
        })
      }
      data.annuaire = []
      for (const o of x.departments) {
        data.annuaire.push({
          title: o.name,
          link: `/annuaire/${o.region_alias}/${o.alias}`
        })
      }
      data.recherchers = []
      for (const o of x.last_watched_legal_unites) {
        data.recherchers.push({
          title: o.company_name,
          link: `/entreprise/${o.url}`
        })
      }
      data.mostPopularCompanies = []
      for (const o of x.most_popular_legal_unites) {
        data.mostPopularCompanies.push({
          title: o.company_name,
          link: `/entreprise/${o.alias}`,
          turnover: o.turnover,
          employees: o.staff_description
        })
      }
    }))
    requests.push(asyncDataLoadHead($api, MAIN_ALIAS, $config.domain, store.getters['scripts/getMerchantName']).then(x => {
      data.title = x.title
      data.h1 = x.h1
      data.meta = x.meta
      data.script = x.script
    }))
    await Promise.all(requests)
    return data
  }
}
</script>
<style lang="scss" scoped>
.main-page {
  padding-bottom: 80px;
}
@include tablet-landscape {
  .main-page {
    padding-bottom: 50px;
  }
}
</style>
