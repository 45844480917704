export default {
  methods: {
    numberSuffix (number, toFixed = 0, allowMore = false, removeZeros = false) {
      if (!number) return `${number}`

      let result = Number.parseFloat(number)
      let preresult = result
      let postfix = ''
      const map = [
        { value: 1e9, suffix: 'Mds' },
        { value: 1e6, suffix: 'M' },
        { value: 1e3, suffix: 'K' }
      ]
      for (const x of map) {
        if (Math.abs(number) >= x.value) {
          preresult = number / x.value
          postfix = x.suffix
          if (allowMore) {
            result = Math.floor(preresult * (10 ** toFixed)) / (10 ** toFixed)
            if (preresult > result) postfix += '+'
          } else {
            result = preresult
          }

          break
        }
      }
      preresult = result.toFixed(toFixed)
      if (removeZeros && toFixed > 0) {
        const parts = preresult.split(/\./)
        if (!/[1-9]/.test(parts[1])) {
          preresult = parts[0]
        }
      }
      return `${preresult}${postfix}`
    },
    /**
     * Отформатировать
     * @param {Number|String} number исходное число
     * @param {Array<int>|null} scheme схема цифр, после которых ставить разделитель
     * @param {String} separator разделитель
     * @returns Отформатированное число
     */
    numberAddSpaces (number, scheme = null, separator = ' ') {
      const n = `${number}`
      // eslint-disable-next-line prefer-const
      let [left, right] = n.split('.')
      if (left.length < 4) {
        return n
      }
      if (right === undefined) {
        right = ''
      }
      let str = ''
      if (scheme != null) {
        let index = 0
        for (let i = 0; i < n.length; i++) {
          if (i !== 0 && index < scheme.length && i % scheme[index] === 0) {
            str += separator
            index += 1
          }
          str += n[i]
        }
      } else {
        str = `${left.slice(left.length - 3, left.length)}${right.length ? `.${right}` : ''}`
        for (let i = left.length - 3 - 3; i >= 0; i -= 3) str = `${left.slice(i, i + 3)}${separator}${str}`

        if (left.length % 3 !== 0) str = `${left.slice(0, left.length % 3)}${separator}${str}`
      }
      return str
    }
  }

}
