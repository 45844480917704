<template>
  <section class="our-features-block">
    <div class="wrapper">
      <h2 class="header">
        La data {{ $store.getters["scripts/getMerchantName"] || 'Docubiz' }}:
      </h2>
      <FeaturesLeftSide class="left-side" />
      <LazyHydrate when-visible>
        <FeaturesRightSide v-if="!isPhone" class="right-side" :items="companies" />
      </LazyHydrate>
    </div>
  </section>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import deviceInfo from '@/mixins/deviceInfo'
import FeaturesLeftSide from './parts/FeaturesLeftSide.vue'

export default {
  name: 'OurFeatures',
  components: {
    FeaturesLeftSide, FeaturesRightSide: () => import('./parts/FeaturesRightSide.vue'), LazyHydrate
  },
  mixins: [deviceInfo],
  props: {
    companies: {
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.our-features-block {
  overflow: hidden;
  padding-bottom: 170px;
  margin-top: 100px;
  margin-bottom: -170px;
  max-width: 100vw;
  @include tablet-landscape {
    & {
      margin-top: 70px;
    }
  }
}

.our-features-block .wrapper {
  max-width: 1230px;
  width: auto;
  margin: 0 auto 0;
  display: grid;
  grid-template-rows: auto 1fr 58px;
  grid-template-columns: max-content auto;

  .header {
    margin: 0 0 30px 30px;
    font-weight: 600;
    @include biggest-font;
    font-family: Poppins, sans-serif;
    grid-row: 1;
    grid-column: 1;
  }

  .left-side {
    grid-row: 2;
    grid-column: 1;
  }

  .right-side {
    grid-row: 1 / 4;
    grid-column: 2;
  }
}
@include tablet-landscape {
  .our-features-block {
    display: block;

    .wrapper {
      margin: auto;
      max-width: 570px;
      display: flex;
      flex-direction: column;

      .header {
        font-size: 36px;
        margin-left: 0;
      }
    }
  }
}

@include phone {
  .our-features-block {
    margin-top: 100px;

    .wrapper .header {
      font-size: 24px;
      text-align: center;
    }
  }

  .our-features-block .left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
