<template>
  <article class="left-side">
    <div class="cards-grid">
      <div class="feature-card">
        <HouseIcon />
        <h3>Fiche entreprise</h3>
        <p class="hashtaged">
          <span>Siren/siret</span> <span>Adresses</span> <span>noms des dirigeants</span> <span>formes juridiques</span>
          <span>Capital social</span> <span>Numéro RCS</span> <span>code NAF</span>
          <span>Effectif</span>
        </p>
      </div>
      <div class="feature-card">
        <ChartGrowthIcon />
        <h3>Infos financières</h3>
        <p class="hashtaged">
          <span>Chiffres d'affaires</span> <span>Résultat d'exploitation</span> <span>BFR</span> <span>EBITDA</span>
          <span>Croissance</span> <span>Dette</span> <span>Bilans</span> <span>Infos réglementées</span>
        </p>
      </div>
      <div class="feature-card">
        <BellIcon />
        <h3>Alertes</h3>
        <p class="hashtaged">
          <span>Accords d'entreprises</span> <span>Procédures Collectives</span> <span>Marques</span>
          <span>Actionnaires & Bénéficiaires</span> <span>Annonces Bodacc</span>
        </p>
      </div>
      <div class="feature-card">
        <BrandLogoIcon />
        <h3>Services + Premium</h3>
        <p class="hashtaged">
          <span>Extrait de KBIS</span> <span>Avis Sirene</span> <span>Contacts dirigeants</span>
          <span>Diagnostic Financier</span> <span>Numéro de TVA</span>
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import BellIcon from '@/assets/svg/icons/bell.svg'
import BrandLogoIcon from '@/assets/svg/icons/brand-logo-icon.svg'
import ChartGrowthIcon from '@/assets/svg/icons/chart-growth.svg'
import HouseIcon from '@/assets/svg/icons/house.svg'
import deviceInfo from '@/mixins/deviceInfo'

export default {
  name: 'FeaturesLeftSide',
  components: {
    HouseIcon,
    ChartGrowthIcon,
    BellIcon,
    BrandLogoIcon
  },
  mixins: [deviceInfo]
}
</script>

<style lang="scss" scoped>
.left-side {
  .cards-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: max-content;
  }

  .feature-card {
    max-width: 374px;
    max-height: 240px;
    min-height: 240px;
    width: 100vw;
    height: 100vh;
    transition: 0.3s;
    position: relative;
    display: grid;
    grid-auto-rows: minmax(0, 1fr);
    grid-template-rows: auto;
    grid-template-columns: 100%;
    padding: var(--card-padding-top) var(--card-padding-right) var(--card-padding-bottom) var(--card-padding-left);
    border-radius: 40px;
    padding: 30px 30px 40px 30px;

    &:hover,
    &:focus-visible {
      outline: none;
      box-shadow: 0px 100px 80px rgba(115, 132, 156, 0.07), 0px 64.8148px 46.8519px rgba(115, 132, 156, 0.0531481),
        0px 38.5185px 25.4815px rgba(115, 132, 156, 0.0425185), 0px 20px 13px rgba(115, 132, 156, 0.035),
        0px 8.14815px 6.51852px rgba(115, 132, 156, 0.0274815), 0px 1.85185px 3.14815px rgba(115, 132, 156, 0.0168519);
    }

    h3 {
      font-weight: 600;
      font-family: Poppins, sans-serif;
      font-size: var(--font-size-normal-l3);
      line-height: var(--line-height-large-s2);
      margin: 16px 0 10px;
    }

    p {
      color: var(--light-gray);
      font-size: var(--font-size-normal-s1);
      line-height: var(--line-height-normal-l1);
    }

    svg {
      height: 37px;
      max-height: 37px;
      width: auto;
      max-width: 40px;
    }
  }
  .hashtaged {
    span::before {
      content: '#';
    }
  }
}

@include tablet-landscape {
  .left-side {
    .cards-grid {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 30px;
    }

    .feature-card {
      padding: 0px;
      text-align: start;
      max-height: 216px;
      min-height: 216px;
      width: 270px;

      &:hover,
      &:focus-visible {
        box-shadow: none;
      }
    }
  }
}

@include phone {
  .left-side {
    .cards-grid {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }

    .feature-card {
      justify-items: center;
      text-align: center;
    }
  }
}
</style>
