<template>
  <section class="text-content">
    <article>
      <slot name="default">
        <p>
          {{ $store.getters["scripts/getMerchantName"] || 'Docubiz' }} a été lancé au cours de l’été 2021 avec pour ambition de démocratiser l’accès à l’information légale
          alors encore trustée par des acteurs historiques figés et profitant d’un quasi monopole pour vendre des
          documents accessibles gratuitement depuis la loi Macron sur l’Open-data.
        </p>
        <p>
          La team {{ $store.getters["scripts/getMerchantName"] || 'Docubiz' }} s’est donc penchée sur la problématique consistant à récupérer la data B2B chez les différents
          acteurs (le Bodacc, L’INPI, l’Insee , Infogreffe ..) puis la traiter et l'agréger afin qu’elle soit mise à
          disposition facilement et gratuitement depuis une seule application web.
        </p>
        <p>
          Après des mois de travail nous sommes fiers de vous présenter {{ $config.domain || 'Docubiz.fr' }}, leader dans le partage d’infos
          légales et financières sur plus de 20 Millions d’entreprises Françaises : Sirent & Siret , fiches entreprises,
          fiches bénéficiaires & dirigeants, cartographie, statuts et bilans, marques déposées, documents juridiques,
          comptes annuels, procédures collectives, infos réglementées des sociétés cotées en bourse et même les accords
          d’entreprises signés !
        </p>
        <p>
          Afin de financer notre croissance et nous permettre de continuer à innover nous proposons une formule payante,
          {{ $store.getters["scripts/getMerchantName"] || 'Docubiz' }} Premium, qui offre des services à forte valeur ajoutée comme les
          <NuxtLink target="_blank" to="/obtenir-kbis">
            KBIS
          </NuxtLink>,
          <NuxtLink target="_blank" to="/numero-tva-intracommunautaire">
            les N° de TVA intracommunautaire
          </NuxtLink>, les
          <NuxtLink target="_blank" to="/avis-situation-sirene-insee">
            avis SIREN
          </NuxtLink>
          ou encore les
          <NuxtLink target="_blank" to="/diagnostic-financier-nota-pme">
            diagnostics financier Nota-PME
          </NuxtLink>
          et la cartographie des dirigeants qui permet d’avoir une vision à 360° sur les ramifications entre les
          entreprises et les mandats des gérants !
        </p>
        <p>
          N’hésitez pas à
          <NuxtLink to="/contact">
            nous contacter
          </NuxtLink>
          pour nous faire part de vos remarques, suggestions , conseils et autres
          encouragements :)
        </p>
        <p>
          Nous vous souhaitons des recherches d’infos fructueuses avec {{ $config.domain || 'Docubiz.fr' }} ..
        </p>
      </slot>
    </article>
  </section>
</template>

<script>
import deviceInfo from '@/mixins/deviceInfo'

export default {
  name: 'TextContent',
  mixins: [deviceInfo]
}
</script>

<style lang="scss" scoped>
.text-content {
  max-width: 970px;
  width: calc(100vw - 40px);
  margin: 80px auto 0;

  & a {
    display: inline-block;
  }

  color: var(--light-gray);
  @include tablet-landscape {
    & {
      margin-top: 70px;
    }
  }
}
</style>

<style lang="scss">
.text-content {
  p {
    margin-bottom: 1.5em;
  }
}
</style>
