export async function asyncDataLoadHead ($api, alias, domain, merchantName) {
  const localDomain = domain ?? 'Docubiz.fr'
  const localMerchant = merchantName ?? 'Docubiz'
  let data = await $api.meta.showByAlias(alias, localDomain)
  let strData = JSON.stringify(data).replaceAll('{merchant_domain}', localDomain)
  strData = strData.replaceAll('{merchant_name}', localMerchant)
  data = JSON.parse(strData)
  return {
    title: data.title,
    h1: data.h1,
    content: data.content,
    search: data.search,
    hidden: data.is_hidden,
    meta: [
      { hid: 'description', name: 'description', content: data.description }
    ],
    script: [
      {
        innerHTML: data.struct,
        type: 'application/ld+json'
      }
    ]
  }
}
