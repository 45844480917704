<template>
  <article class="news-column" :class="{'tab-navigation':tabNavigationActive}">
    <div
      ref="wrapper"
      class="wrapper"
      tabindex="0"
      @keydown="keydown"
      @scroll="onScroll"
    >
      <h3>
        <slot name="title">
          title
        </slot>
      </h3>
      <template v-for="(item,i) in items.slice(0,visibleItems)">
        <slot v-bind="{item,index:i}" name="one-news">
          <article class="one-news">
            <NuxtLink
              :to="`/entreprise/${item.alias}?scroll=${scrollBlock}`"
              :tabindex="tabNavigationActive ? 0 : -1"
              class="news-link"
              @focusin.native="e => focusin(e,1 + i*2)"
              @focusout.native="focusout"
            >
              {{ item.title }}
            </NuxtLink>
            <p>{{ item.text }}</p>
            <time :datetime="item.date">{{ item.date }}</time>
          </article>
        </slot>
        <hr v-if="i !== items.length-1" :key="'hr'+i">
      </template>
    </div>
  </article>
</template>

<script>
import deviceInfo from '@/mixins/deviceInfo'
import MathUtils from '~/services/MathUtils'

export default {
  name: 'NewsColumn',
  mixins: [deviceInfo],
  props: {
    items: {
      default: () => [
        {
          date: new Date().toISOString(), title: 'title', text: 'some text', alias: 'alias'
        }
      ]
    },
    scrollBlock: {
      default: ''
    }
  },
  data () {
    return {
      tabNavigationActive: false,
      focusedIndex: null,
      visibleItems: 7
    }
  },
  methods: {
    onScroll (e) {
      if (this.itemsEnded) return
      cancelAnimationFrame(this.frame)
      this.frame = requestAnimationFrame(x => {
        if (e.target.scrollHeight - e.target.scrollTop < 700) {
          this.visibleItems += 7
        }
        if (this.visibleItems >= this.items.length) { this.itemsEnded = true }
      })
    },
    focusin (e, i) {
      e.target.closest('.news-link').scrollIntoView({ behaviour: 'smooth', block: 'center' })
      this.focusedIndex = i
    },
    focusout (e) {
      this.focusedIndex = null
    },
    keydown (e) {
      if (/[Ee]scape/.test(e.key)) {
        this.tabNavigationActive = false
        this.$refs.wrapper.focus()
      } else if (/[Ee]nter/.test(e.key) && !this.tabNavigationActive) {
        this.tabNavigationActive = true
        this.focusFirstNews()
        e.preventDefault()
        e.stopPropagation()
      } else if (/arrow/i.test(e.key)) {
        const step = /arrowup/i.test(e.key) ? -2 : (/arrowdown/i.test(e.key) ? 2 : 0)
        const target = MathUtils.clamp(this.focusedIndex + step, 1, this.$refs.wrapper.children.length - 1)
        this.$refs.wrapper.children[target].focus()
        e.preventDefault()
      }
    },
    focusFirstNews () {
      this.$refs.wrapper.children[1].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.news-column {
  border: 1px solid var(--separator);
  border-radius: 10px;
  max-height: 500px;
  max-width: 370px;
  height: 100vh;
  width: calc(100vw - 40px);

  &.tab-navigation {
    transition: all 0.25s ease;
    outline-offset: 4px;
    outline: 1px transparent solid;

    &:focus-within {
      outline-offset: -1px;
      outline: 1px var(--hovered-brand) solid;
    }
  }

  .wrapper {
    overflow: scroll;
    scrollbar-width: thin;
    height: 100%;
    max-height: calc(100% - 60px);
    max-width: calc(100% - 38px);
    width: 100%;
    padding-right: 8px;
    margin: 30px 8px 30px 30px;

    &:focus-visible {
      .news-link {
        color: var(--hovered-brand);
      }
    }
  }

  h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--font-size-normal-l2);
    line-height: var(--line-height-normal-l2);
    font-weight: 600;
    font-family: Poppins, sans-serif;
    gap: 24px;
    position: sticky;
    top: 0;
    background: var(--white);
    margin: 0;
    z-index: 1;
    transition: all 0.25s ease;

    svg {
      max-width: 35px;
      max-height: 35px;
      height: 35px;
      width: 100vw;
    }
  }

  .one-news {
    position: relative;
    padding-top: 21px;

    &:nth-child(2) {
      margin-top: 20px;
    }

    time {
      font-size: var(--font-size-small-l1);
      line-height: var(--line-height-normal-s1);
      color: var(--light-gray);
      position: absolute;
      top: 0;
      left: 0;
    }

    p {
      margin-top: 0;
      color: var(--paragraph);
      line-height: var(--line-height-normal-l1);
    }
  }

  hr {
    margin: 20px 0 20px;
    overflow: visible;
  }

  .news-link {
    outline: none;
    margin: 0;
    color: var(--brand);
    font-size: var(--font-size-normal);
    line-height: var(--line-height-normal-l1-2);
    font-weight: 600;
    transition: all 0.25s ease;
    position: relative;
    text-decoration: underline transparent solid 2px;

    &:focus-visible {
      text-decoration-color: var(--hovered-brand);
    }
  }
}

@include tablet-landscape {
  .news-column {
    h3 {
      font-size: var(--font-size-normal-l1);
      line-height: var(--line-height-normal-l1-3);
      margin-bottom: 30px;
    }
  }
}
</style>
