<template>
  <div class="wrapped-list" :class="direction === 'row' && !isMobile ? 'row-direction' : null" :style="style">
    <ul
      v-show="items.length"
      ref="list"
      style="height: var(--height); margin: 0"
      :class="{animated}"
    >
      <li v-for="(item,i) in innerItems.slice(0, maxItemsCurrent)" :key="i">
        <component
          :is="item[linkField].startsWith('http') ? 'a' : 'NuxtLink'"
          tabindex="0"
          class="wrapped-link"
          :href="item[linkField]"
          :to="item[linkField]"
        >
          <slot name="default">
            <ChevronRight class="marker" />
            <span :title="item[titleField]" class="text">{{ item[titleField] }}</span>
          </slot>
        </component>
      </li>
    </ul>
    <div v-if="!items.length" class="list-empty">
      Il n’y a aucun résultat correspondant à votre demande
    </div>
    <div v-if="buttonShowMore" class="show-more" @click="showMore = !showMore">
      {{ showMore ? 'Cacher' : 'Afficher tout' }}
    </div>
  </div>
</template>
<script>
import ChevronRight from '@/assets/svg/chevron-right.svg'
import deviceInfo from '@/mixins/deviceInfo'

export default {
  name: 'WrappedList',
  components: {
    ChevronRight
  },
  mixins: [deviceInfo],
  props: {
    height: {
      default: '168'
    },
    direction: {
      default: false
    },
    items: {
      default: () => [
        { title: 'ao', link: 'http:23.s' },
        { title: 'ao', link: '/fgkgjf' }
      ]
    },
    titleField: {
      default: 'title'
    },
    linkField: {
      default: 'link'
    },
    buttonShowMore: {
      default: false
    },
    showAllMobile: {
      default: false
    },
    maxItems: {
      default: () => ({
        mobile: 5,
        desktop: 15
      })
    },
    columnCount: {
      default: 3
    },
    isFlexHeight: {
      default: false
    }
  },
  data () {
    return {
      showMore: false,
      extraHeight: 0,
      flexHeight: null,
      animated: false
    }
  },
  computed: {
    style () {
      const data = {
        '--columns': this.columnCount,
        '--height': `${this.localHeight}px`
      }
      if (this.isFlexHeight && this.flexHeight) {
        data['--height'] = `${this.flexHeight}px`
      }
      if (this.columnCount === 1 || this.direction) { data['--height'] = 'auto' }
      return data
    },
    localHeight () {
      return Number.parseInt(this.height, 10) + this.extraHeight
    },
    maxItemsCurrent () {
      if (this.isPhone) return this.maxItems.mobile
      return this.maxItems.desktop
    },
    innerItems () {
      let maxItems = 0
      if (this.showMore) {
        maxItems = this.maxItems.desktop
      } else if (typeof this.maxItems === 'number') {
        maxItems = this.maxItems
      } else {
        maxItems = this.maxItems.desktop
      }

      return this.items.slice(0, maxItems)
    }
  },
  watch: {
    items (n, o) {
      this.calcHeight(n)
    },
    showMore () {
      this.$nextTick(() => {
        if (this.showMore) {
          this.extraHeight = this.$refs.list.scrollHeight - this.height
        } else {
          this.extraHeight = 0
        }
      })
    },
    columnCount (n, o) {
      this.calcHeight(this.items)
    }
  },
  mounted () {
    this.calcHeight(this.items)
    requestAnimationFrame(() => this.animated = true)
  },
  methods: {
    calcHeight (items) {
      const { firstChild } = this.$refs.list
      let childHeight = 24 + 12 // TODO возможно переделать дефолтную высоту элемента
      if (firstChild) {
        childHeight = firstChild.scrollHeight + 12
      }
      if (!this.isPhone) {
        this.flexHeight = (items.length / this.columnCount) * childHeight + 20
      } else {
        this.flexHeight = items.length * childHeight
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapped-list {
  position: relative;

  .list-empty {
    font-family: 'Open Sans';
    @include normal-font;
    color: var(--paragraph);
  }

  ul {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 12px 30px;
    padding: 0;
    align-content: flex-start;

    li {
      &::marker {
        content: none;
      }

      width: calc(100% / var(--columns) - (30px * (var(--columns) - 1)) / var(--columns));
    }

    &.animated {
      transition: all 0.5s ease;
    }
  }

  .marker {
    display: inline-block;
    height: 24px;
    @include flex-line-start;
    margin-right: 7px;
    margin-left: 7px;
    min-width: 8px;
  }

  .text {
    margin-top: -2px;
  }

  a {
    color: var(--paragraph);
    text-decoration: none;
    font-size: var(--font-size-normal-s1);
    font-weight: 600;
    line-height: var(--line-height-normal-l1);
    @include flex-line-start;
    gap: 7px;
    cursor: pointer;

    .text {
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 5px;
    }

    .text::after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 0;
      height: 1px;
      background: var(--hovered-brand);
      content: '';
      transition: all 0.25s ease;
    }

    &:hover {
      color: var(--hovered-brand);
      --svg-color: currentColor;

      svg {
        animation: little-jump 2s ease 0s infinite normal forwards;
        --base-offset-x: 0px;
        --max-offset-x: 5px;
      }
    }

    &:focus-visible {
      color: var(--hovered-brand);
      --svg-color: currentColor;

      .text::after {
        width: calc(100% + 10px);
      }

      outline: none;
    }
  }
  @include phone {
    & {
      ul {
        flex-wrap: nowrap;
        overflow: hidden;
        max-width: calc(100vw - 40px);

        li {
          width: 100%;
        }
      }

      .show-more {
        display: block;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 160%;
        text-decoration-line: underline;
        color: var(--brand);
        margin-top: 30px;
      }
    }
  }

  .show-more {
    display: none;
  }
}

.row-direction {
  ul {
    flex-direction: row;
  }
}
</style>
