import {
  ACCORDS_ID,
  ACTIVITY_ID,
  ANNUAL_REPORTS_ID, BENEFICIAIRES_ID,
  BODACC_ID, BRANDS_ID,
  COLLECTIVE_PROCEDURES_ID,
  DIRIGEANTS_ID,
  ESTABLISHMENTS_ID,
  FINANCE_STATISTIC_ID,
  FINANCIERE_ID, GRAPH_ID,
  LEGAL_DOCUMENTS_ID,
  LEGAL_INFO_ID, OBSERVATIONS_ID, PREMIUM_CARD, SEO_SPIN_ID, SIMILAR_COMPANIES_ID
} from '~/services/consts'

export default {
  computed: {
    allIds () {
      return [
        ACCORDS_ID,
        ACTIVITY_ID,
        ANNUAL_REPORTS_ID, BENEFICIAIRES_ID,
        BODACC_ID, BRANDS_ID, PREMIUM_CARD,
        COLLECTIVE_PROCEDURES_ID,
        DIRIGEANTS_ID,
        ESTABLISHMENTS_ID,
        FINANCE_STATISTIC_ID,
        FINANCIERE_ID, GRAPH_ID,
        LEGAL_DOCUMENTS_ID,
        LEGAL_INFO_ID, OBSERVATIONS_ID, SEO_SPIN_ID, SIMILAR_COMPANIES_ID
      ]
    },
    legalInfoId () {
      return LEGAL_INFO_ID
    },
    activityId () {
      return ACTIVITY_ID
    },
    dirigeantsId () {
      return DIRIGEANTS_ID
    },
    establishmentsId () {
      return ESTABLISHMENTS_ID
    },
    premiumId () {
      return PREMIUM_CARD
    },
    bodaccId () {
      return BODACC_ID
    },
    legalDocumentsId () {
      return LEGAL_DOCUMENTS_ID
    },
    annualReportsId () {
      return ANNUAL_REPORTS_ID
    },
    financeStatisticId () {
      return FINANCE_STATISTIC_ID
    },
    collectiveProceduresId () {
      return COLLECTIVE_PROCEDURES_ID
    },
    financiereId () {
      return FINANCIERE_ID
    },
    accordsId () {
      return ACCORDS_ID
    },
    observationsId () {
      return OBSERVATIONS_ID
    },
    beneficiairesId () {
      return BENEFICIAIRES_ID
    },
    brandsId () {
      return BRANDS_ID
    },
    similarCompaniesId () {
      return SIMILAR_COMPANIES_ID
    },
    seoSpinId () {
      return SEO_SPIN_ID
    },
    graphId () {
      return GRAPH_ID
    }
  }
}
